import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitCamelCase'
})
export class SplitCamelCasePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let output, i, l;
    const capRe = /[A-Z]/;
    output = '';
    for (i = 0, l = value.length; i < l; i += 1) {
        if (i === 0) {
            output += value[i].toUpperCase();
        } else {
            if (i > 0 && capRe.test(value[i])) {
                output += ' ';
            }
            output += value[i];
        }
    }
    return output;
}

}
