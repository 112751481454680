<div class="modal-body">
  <div class="text-center mx-auto pb-3" [style]="'max-width: ' + contentWidth + ';'">
    <div style="min-height: 150px;">
      <ng-lottie [options]="{ path: './assets/lottie/success.json', loop: false, autoplay: true }" width="150px"
                   height="150px" containerClass="mx-auto"></ng-lottie>
    </div>
    <div class="fs-18 mb-3" style="word-break: break-word;" [innerHTML]="message"></div>
    <button class="btn btn-success" (click)="close()">Done</button>
  </div>
</div>
