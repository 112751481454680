/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { QueryResultsVerificationRequestDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface SearchVerificationRequestsRequestParams {
    apiClientName?: string;
    lastUpdatedAt?: string;
    fulfillmentReconciledAt?: string;
    hClone?: string;
    forwardingRetryCount?: number;
    identifierType?: 'UIN' | 'TOKEN';
    responseDataSizeInBytes?: number;
    cloneTransactionId?: string;
    cbsSyncStatus?: 'SENT' | 'FULFILLED';
    createdAt?: string;
    biometricRequest?: boolean;
    apiCustomerName?: string;
    verificationApiClient?: string;
    hcloneClientName?: string;
    id?: number;
    responseReceivedAt?: string;
    forwardedForProcessing?: boolean;
    identifier?: string;
    verificationProofRequired?: boolean;
    lastUpdatedBy?: string;
    verificationStatus?: 'MATCH_FOUND' | 'MATCH_NOT_FOUND' | 'FAILED';
    clientRequestId?: string;
    errorMessage?: string;
    reasonForRequest?: string;
    attributeSetName?: 'LEVEL_ONE' | 'LEVEL_TWO' | 'LEVEL_THREE' | 'LEVEL_FOUR' | 'LEVEL_FIVE';
    sourceIp?: string;
    requestDataSizeInBytes?: number;
    createdBy?: string;
    idmsVerificationTransactionRef?: string;
    verificationType?: 'READ_ATTRIBUTE' | 'VERIFY_ID';
    clientSystemName?: string;
    verificationRequestData?: string;
    offset?: number;
    endDate?: string;
    limit?: number;
    startDate?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class VerificationRequestControllerService {

    protected basePath = 'http://hgs.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchVerificationRequests(requestParameters: SearchVerificationRequestsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsVerificationRequestDto>;
    public searchVerificationRequests(requestParameters: SearchVerificationRequestsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsVerificationRequestDto>>;
    public searchVerificationRequests(requestParameters: SearchVerificationRequestsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsVerificationRequestDto>>;
    public searchVerificationRequests(requestParameters: SearchVerificationRequestsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const apiClientName = requestParameters.apiClientName;
        const lastUpdatedAt = requestParameters.lastUpdatedAt;
        const fulfillmentReconciledAt = requestParameters.fulfillmentReconciledAt;
        const hClone = requestParameters.hClone;
        const forwardingRetryCount = requestParameters.forwardingRetryCount;
        const identifierType = requestParameters.identifierType;
        const responseDataSizeInBytes = requestParameters.responseDataSizeInBytes;
        const cloneTransactionId = requestParameters.cloneTransactionId;
        const cbsSyncStatus = requestParameters.cbsSyncStatus;
        const createdAt = requestParameters.createdAt;
        const biometricRequest = requestParameters.biometricRequest;
        const apiCustomerName = requestParameters.apiCustomerName;
        const verificationApiClient = requestParameters.verificationApiClient;
        const hcloneClientName = requestParameters.hcloneClientName;
        const id = requestParameters.id;
        const responseReceivedAt = requestParameters.responseReceivedAt;
        const forwardedForProcessing = requestParameters.forwardedForProcessing;
        const identifier = requestParameters.identifier;
        const verificationProofRequired = requestParameters.verificationProofRequired;
        const lastUpdatedBy = requestParameters.lastUpdatedBy;
        const verificationStatus = requestParameters.verificationStatus;
        const clientRequestId = requestParameters.clientRequestId;
        const errorMessage = requestParameters.errorMessage;
        const reasonForRequest = requestParameters.reasonForRequest;
        const attributeSetName = requestParameters.attributeSetName;
        const sourceIp = requestParameters.sourceIp;
        const requestDataSizeInBytes = requestParameters.requestDataSizeInBytes;
        const createdBy = requestParameters.createdBy;
        const idmsVerificationTransactionRef = requestParameters.idmsVerificationTransactionRef;
        const verificationType = requestParameters.verificationType;
        const clientSystemName = requestParameters.clientSystemName;
        const verificationRequestData = requestParameters.verificationRequestData;
        const offset = requestParameters.offset;
        const endDate = requestParameters.endDate;
        const limit = requestParameters.limit;
        const startDate = requestParameters.startDate;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (apiClientName !== undefined && apiClientName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>apiClientName, 'apiClientName');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (fulfillmentReconciledAt !== undefined && fulfillmentReconciledAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fulfillmentReconciledAt, 'fulfillmentReconciledAt');
        }
        if (hClone !== undefined && hClone !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hClone, 'HClone');
        }
        if (forwardingRetryCount !== undefined && forwardingRetryCount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>forwardingRetryCount, 'forwardingRetryCount');
        }
        if (identifierType !== undefined && identifierType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifierType, 'identifierType');
        }
        if (responseDataSizeInBytes !== undefined && responseDataSizeInBytes !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>responseDataSizeInBytes, 'responseDataSizeInBytes');
        }
        if (cloneTransactionId !== undefined && cloneTransactionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cloneTransactionId, 'cloneTransactionId');
        }
        if (cbsSyncStatus !== undefined && cbsSyncStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>cbsSyncStatus, 'cbsSyncStatus');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (biometricRequest !== undefined && biometricRequest !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>biometricRequest, 'biometricRequest');
        }
        if (apiCustomerName !== undefined && apiCustomerName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>apiCustomerName, 'apiCustomerName');
        }
        if (verificationApiClient !== undefined && verificationApiClient !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>verificationApiClient, 'verificationApiClient');
        }
        if (hcloneClientName !== undefined && hcloneClientName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hcloneClientName, 'hcloneClientName');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (responseReceivedAt !== undefined && responseReceivedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>responseReceivedAt, 'responseReceivedAt');
        }
        if (forwardedForProcessing !== undefined && forwardedForProcessing !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>forwardedForProcessing, 'forwardedForProcessing');
        }
        if (identifier !== undefined && identifier !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifier, 'identifier');
        }
        if (verificationProofRequired !== undefined && verificationProofRequired !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>verificationProofRequired, 'verificationProofRequired');
        }
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (verificationStatus !== undefined && verificationStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>verificationStatus, 'verificationStatus');
        }
        if (clientRequestId !== undefined && clientRequestId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientRequestId, 'clientRequestId');
        }
        if (errorMessage !== undefined && errorMessage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>errorMessage, 'errorMessage');
        }
        if (reasonForRequest !== undefined && reasonForRequest !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reasonForRequest, 'reasonForRequest');
        }
        if (attributeSetName !== undefined && attributeSetName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>attributeSetName, 'attributeSetName');
        }
        if (sourceIp !== undefined && sourceIp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sourceIp, 'sourceIp');
        }
        if (requestDataSizeInBytes !== undefined && requestDataSizeInBytes !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requestDataSizeInBytes, 'requestDataSizeInBytes');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (idmsVerificationTransactionRef !== undefined && idmsVerificationTransactionRef !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>idmsVerificationTransactionRef, 'idmsVerificationTransactionRef');
        }
        if (verificationType !== undefined && verificationType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>verificationType, 'verificationType');
        }
        if (clientSystemName !== undefined && clientSystemName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientSystemName, 'clientSystemName');
        }
        if (verificationRequestData !== undefined && verificationRequestData !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>verificationRequestData, 'verificationRequestData');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (endDate !== undefined && endDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>endDate, 'endDate');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (startDate !== undefined && startDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startDate, 'startDate');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsVerificationRequestDto>(`${this.configuration.basePath}/verification-requests`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
