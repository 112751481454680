import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";


@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  user: any;
  stop$ = new Subject();

  constructor(private router: Router) { }

  ngOnInit(): void {

  }

  goBackHome(){
    if (this.user) {
      this.router.navigate(['/dashboard'])
    }else{
      this.router.navigate(['/'])
    }
  }

}
