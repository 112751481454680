  <div class="modal-header">
  <h5 class="modal-title"> {{modalHeader}} </h5>
  <button type="button" class="btn-close" (click)="close()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <alert *ngIf="errorMessage" [type]="'danger'" dismissOnTimeout="10000">{{errorMessage}}</alert>
  <form [formGroup]="disapproveForm">
  <div class="mb-3">
    <div class="alert alert-info">
      <span [innerHTML]="infoMessage">You are about to deactivate <strong>Olalekan Ayomide’s</strong> account. Kindly confirm this action by providing a reason below and clicking deactivate.</span>
    </div>

    <div class="form-group">
      <label class="fw-bold" [innerHTML]="inputLabel"></label>
      <textarea class="form-control" formControlName="reason" style="min-height: 130px; resize: unset;"></textarea>
    </div>
  </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" [class]="'btn ' + buttonClass" [disabled]="isLoading" (click)="disapprove()"><span class="iconify icon" [attr.data-icon]="buttonIcon"></span> {{buttonLabel}}</button>
</div>
