/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface HeartbeatResponsePojo { 
    acknowledgedRecords?: number;
    totalRecordsInControllerForClone?: number;
    cloneAccessLevel?: string;
    pendingCommands?: Set<HeartbeatResponsePojo.PendingCommandsEnum>;
}
export namespace HeartbeatResponsePojo {
    export type PendingCommandsEnum = 'CLEAR_DATA' | 'DISCONNECT' | 'DEACTIVATE' | 'REACTIVATE' | 'RECONNECT';
    export const PendingCommandsEnum = {
        ClearData: 'CLEAR_DATA' as PendingCommandsEnum,
        Disconnect: 'DISCONNECT' as PendingCommandsEnum,
        Deactivate: 'DEACTIVATE' as PendingCommandsEnum,
        Reactivate: 'REACTIVATE' as PendingCommandsEnum,
        Reconnect: 'RECONNECT' as PendingCommandsEnum
    };
}


