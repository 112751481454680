/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HCloneActionRequestPojo } from './h-clone-action-request-pojo';
import { NodeAddressPojo } from './node-address-pojo';


export interface HClonePojo { 
    id?: number;
    clientId?: string;
    clientSecret?: string;
    isSetupComplete?: boolean;
    name?: string;
    description?: string;
    publicKey?: string;
    keyExchangeCompleted?: boolean;
    numberOfRecordsForActivation?: number;
    baseUrl?: string;
    adminFirstName?: string;
    adminLastName?: string;
    adminPhoneNumber?: string;
    adminEmail?: string;
    adminUin?: string;
    sourceIpAddresses?: string;
    cbsCustomerAccountId?: string;
    settings?: { [key: string]: string; };
    totalRecordsInClone?: number;
    lastAcknowledgementTime?: string;
    pendingSyncToCloneCount?: number;
    maxSyncPerDay?: number;
    status?: HClonePojo.StatusEnum;
    lastActivationCommand?: HClonePojo.LastActivationCommandEnum;
    lastConnectionCommand?: HClonePojo.LastConnectionCommandEnum;
    pendingActionRequests?: Array<HCloneActionRequestPojo>;
    address?: NodeAddressPojo;
}
export namespace HClonePojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'PENDING';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Pending: 'PENDING' as StatusEnum
    };
    export type LastActivationCommandEnum = 'CLEAR_DATA' | 'DISCONNECT' | 'DEACTIVATE' | 'REACTIVATE' | 'RECONNECT';
    export const LastActivationCommandEnum = {
        ClearData: 'CLEAR_DATA' as LastActivationCommandEnum,
        Disconnect: 'DISCONNECT' as LastActivationCommandEnum,
        Deactivate: 'DEACTIVATE' as LastActivationCommandEnum,
        Reactivate: 'REACTIVATE' as LastActivationCommandEnum,
        Reconnect: 'RECONNECT' as LastActivationCommandEnum
    };
    export type LastConnectionCommandEnum = 'CLEAR_DATA' | 'DISCONNECT' | 'DEACTIVATE' | 'REACTIVATE' | 'RECONNECT';
    export const LastConnectionCommandEnum = {
        ClearData: 'CLEAR_DATA' as LastConnectionCommandEnum,
        Disconnect: 'DISCONNECT' as LastConnectionCommandEnum,
        Deactivate: 'DEACTIVATE' as LastConnectionCommandEnum,
        Reactivate: 'REACTIVATE' as LastConnectionCommandEnum,
        Reconnect: 'RECONNECT' as LastConnectionCommandEnum
    };
}


