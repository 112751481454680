/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewCloneDto { 
    clientId: string;
    clientSecret: string;
    name: string;
    baseUrl: string;
    clientEmail: string;
    adminFirstName: string;
    adminLastName: string;
    description?: string;
    cloneIP: string;
    phoneNumber: string;
    nin: string;
    sourceIpAddresses: string;
    zipCode: string;
    street: string;
    houseNumber: string;
    lgaId: number;
    alpha3: string;
    stateId: number;
    scopes?: Array<NewCloneDto.ScopesEnum>;
}
export namespace NewCloneDto {
    export type ScopesEnum = 'HCLONE';
    export const ScopesEnum = {
        Hclone: 'HCLONE' as ScopesEnum
    };
}


