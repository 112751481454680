/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { State } from './state';
import { PortalUser } from './portal-user';


export interface Lga { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: Lga.StatusEnum;
    deactivatedBy?: PortalUser;
    name?: string;
    code?: string;
    crmLgaId?: string;
    state?: State;
}
export namespace Lga {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'PENDING';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Pending: 'PENDING' as StatusEnum
    };
}


