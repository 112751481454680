import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  @Input() modalHeader:string = 'Confirm action';
  @Input() infoMessage:string = 'Kindly confirm this action';

  @Input() confirmLabel:string = 'Confirm';
  @Input() cancelLabel:string = 'Cancel';

  @Input() showCloseButton:boolean = false;

  @Input() dataIcon:string = '';
  @Input() confirmButtonClass:string = 'btn-primary';


  @Input() onConfirm: () => void;
  @Input() onCancel: () => void;

  @Output() onSuccessEmitter = new EventEmitter();

  isLoading:boolean;


  @Input() reasonNeeded: boolean = false;
  disapprovalReason: string;
  errorMessage: string = ""

  constructor(
    private bsModalRef: BsModalRef
  ) {
  }

  ngOnInit(): void {
  }

  close(): void {
    this.onSuccessEmitter.emit();
    this.bsModalRef.hide();
    this.onCancel();
  }

  confirm(){
    this.errorMessage = "";
    if (this.reasonNeeded) {
      if (this.disapprovalReason.trim() == '') {
        this.errorMessage = "Please, enter a reason for disapproval";
        return;
      }
    }

    this.isLoading = true;
    this.onConfirm();
  }

}
