import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {KeycloakAngularModule, KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {LottieModule} from 'ngx-lottie';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ForbiddenComponent} from "./modules/extranet/error-pages/forbidden/forbidden.component";
import {NotFoundComponent} from './modules/extranet/error-pages/not-found/not-found.component';
import {SiteHeaderComponent} from './modules/layout/site-header/site-header.component';
import {SiteFooterComponent} from './modules/layout/site-footer/site-footer.component';
import {environment} from "../environments/environment";
import {IntranetSideHeaderComponent} from './modules/layout/intranet-site-header/intranet-side-header.component';
import {LandingPageComponent} from './modules/extranet/landing-page/landing-page.component';
import {SharedModule} from './shared/shared.module';
import {DatePipe} from "@angular/common";
import {AuditTrailControllerService, BASE_PATH} from "../../sdk/hgs-api-sdk";
import {CUSTOM_ERROR_MESSAGES, ErrorMessage} from "ng-bootstrap-form-validation";
import {HttpInterceptorService} from "./shared/authentication/http-interceptor.service";
import {AuthenticationService} from "./shared/authentication/authentication-service.service";
import {AuthenticationComponent} from "./modules/extranet/authentication/authentication.component";
import {AlertModule} from "ngx-bootstrap/alert";
import {filter, mergeMap} from "rxjs/operators";
import {Observable} from "rxjs";
import {FingerprintAuthModule} from "./modules/extranet/fingerprint-auth/fingerprint-auth.module";

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}

export const CUSTOM_ERRORS: ErrorMessage[] = [
  {
    error: 'dataExists',
    format: label => `${label} already exists.`
  },
  {
    error: 'nigerianNumber',
    format: label => `${label} not a valid nigerian number.`
  },
  {
    error: 'whitespace',
    format: label => `${label} cannot be an empty space`
  },
  {
    error: 'invalidEmail',
    format: label => `This ${label} does not exist`
  },
  {
    error: 'networkError',
    format: label => `${label} cannot validated at the moment. Check Network`
  },
  {
    error: 'emailExists',
    format: label => `${label} already exists.`
  },
  {
    error: 'phoneNumberExists',
    format: label => `${label} already exists.`
  },
  {
    error: 'nameExists',
    format: label => `The combination of Company Name and Branch Name already exists.`
  },
];


function initializeKeycloak(keycloak: KeycloakService,
                            authenticationService: AuthenticationService,
                            loggerService: AuditTrailControllerService) {
  const STORAGE_KEY = 'isFirstLogin';
  let isFirstLogin =
    localStorage.getItem(STORAGE_KEY) === null || localStorage.getItem(STORAGE_KEY) == 'true';

  keycloak.keycloakEvents$
    .pipe(
      filter((value) => value.type === KeycloakEventType.OnAuthSuccess),
      mergeMap((value) => {
        return authenticationService
          .fetchUser()
          .toPromise()
          .then((res) => {
            if (isFirstLogin) {
              isFirstLogin = false;
              localStorage.setItem(STORAGE_KEY, 'false');
              return loggerService.logLoginAction({auditTrailDto: {}}).subscribe();
            } else {
              return new Observable();
            }
          });
      })
    ).subscribe();
  return () =>
    keycloak.init({
      config: environment.keycloakConfig,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      }
    });
}

@NgModule({
  declarations: [AppComponent, ForbiddenComponent, SiteHeaderComponent, SiteFooterComponent, IntranetSideHeaderComponent, NotFoundComponent, LandingPageComponent, AuthenticationComponent],
  imports: [
    AppRoutingModule, BrowserModule,
    KeycloakAngularModule, HttpClientModule, FormsModule, ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    LottieModule.forRoot({player: playerFactory}),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(), AlertModule,
    FingerprintAuthModule.forRoot()
  ],
  providers: [
    DatePipe,
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
    {
      provide: BASE_PATH,
      useValue: environment.apiBaseUrl
    },
    {
      provide: CUSTOM_ERROR_MESSAGES,
      useValue: CUSTOM_ERRORS,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AuthenticationService, AuditTrailControllerService]
    },
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
