import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from "../../../shared/authentication/authentication-service.service";

@Component({
  selector: 'app-intranet-side-header',
  templateUrl: './intranet-side-header.component.html',
  styleUrls: ['./intranet-side-header.component.css']
})
export class IntranetSideHeaderComponent implements OnInit {

  user: any;
  routeData: any;

  constructor(private router: Router,
              route: ActivatedRoute, private authenticationService: AuthenticationService) {
    router.events.forEach(e => {
      if (e instanceof NavigationEnd) {
        this.routeData = route?.root?.firstChild?.firstChild?.snapshot?.data || route?.root?.firstChild?.snapshot?.data;
      }
    })
  }

  ngOnInit(): void {

  }

  async logout() {
    // await this.keycloak.getKeycloakInstance().logout({})
  }

  canViewAnalytics() {
    return true;
  }

  canViewCloneSearch() {
    return this.authenticationService.hasPermission('VIEW_CLONE');
  }

  canViewMasterSetting() {
    return this.authenticationService.hasPermission('VIEW_SETTING');
  }

  canViewAuditTrail() {
    return this.authenticationService.hasPermission('VIEW_AUDIT_TRAIL')
  }

  canViewCloneClients() {
    return this.authenticationService.hasPermission('VIEW_CLONE_CLIENT');
  }
}
