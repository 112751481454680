/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RepatriatedActivityLogPojo { 
    actorEmail?: string;
    actorEventTime?: string;
    actorMaskedUIN?: string;
    actorPermission?: string;
    actorRole?: string;
    actorUUID?: string;
    auditTransactionId?: string;
    authenticationMethod?: RepatriatedActivityLogPojo.AuthenticationMethodEnum;
    createdAt?: string;
    description?: string;
    entityName?: string;
    eventStatus?: RepatriatedActivityLogPojo.EventStatusEnum;
    eventType?: RepatriatedActivityLogPojo.EventTypeEnum;
    ipAddress?: string;
    lastLoginAt?: string;
    recipientEmail?: string;
    recipientUUID?: string;
    recordId?: number;
    recordType?: string;
    resource?: string;
    resourceUri?: string;
    serverEventTime?: string;
    systemCode?: string;
    userAgent?: string;
    actorName?: string;
    attemptedAttack?: boolean;
}
export namespace RepatriatedActivityLogPojo {
    export type AuthenticationMethodEnum = 'PASSWORD' | 'FINGERPRINT';
    export const AuthenticationMethodEnum = {
        Password: 'PASSWORD' as AuthenticationMethodEnum,
        Fingerprint: 'FINGERPRINT' as AuthenticationMethodEnum
    };
    export type EventStatusEnum = 'SUCCESSFUL' | 'FAILED';
    export const EventStatusEnum = {
        Successful: 'SUCCESSFUL' as EventStatusEnum,
        Failed: 'FAILED' as EventStatusEnum
    };
    export type EventTypeEnum = 'CREATE' | 'DELETE' | 'UPDATE' | 'SELECT' | 'LOGIN' | 'LOGOUT' | 'OPERATION_LOG' | 'GENERIC_LOG' | 'SIEM' | 'BIOMETRIC_LOGIN';
    export const EventTypeEnum = {
        Create: 'CREATE' as EventTypeEnum,
        Delete: 'DELETE' as EventTypeEnum,
        Update: 'UPDATE' as EventTypeEnum,
        Select: 'SELECT' as EventTypeEnum,
        Login: 'LOGIN' as EventTypeEnum,
        Logout: 'LOGOUT' as EventTypeEnum,
        OperationLog: 'OPERATION_LOG' as EventTypeEnum,
        GenericLog: 'GENERIC_LOG' as EventTypeEnum,
        Siem: 'SIEM' as EventTypeEnum,
        BiometricLogin: 'BIOMETRIC_LOGIN' as EventTypeEnum
    };
}


