/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VerificationApiClientCloneDto { 
    cloneName?: string;
    cloneId?: number;
    status?: VerificationApiClientCloneDto.StatusEnum;
    comment?: string;
}
export namespace VerificationApiClientCloneDto {
    export type StatusEnum = 'SUCCESSFUL' | 'FAILED' | 'PENDING';
    export const StatusEnum = {
        Successful: 'SUCCESSFUL' as StatusEnum,
        Failed: 'FAILED' as StatusEnum,
        Pending: 'PENDING' as StatusEnum
    };
}


