import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {ForbiddenComponent} from "./modules/extranet/error-pages/forbidden/forbidden.component";
import {NotFoundComponent} from './modules/extranet/error-pages/not-found/not-found.component';
import { LandingPageComponent } from './modules/extranet/landing-page/landing-page.component';
import {AuthenticationComponent} from "./modules/extranet/authentication/authentication.component";
import {LoggedInGuard} from "./shared/guard/logged-in.guard";
import {OtpGuard} from "./shared/guard/otp.guard";
import {MultiFactorAuthenticationGuard} from "./shared/guard/multi-factor-authentication-guard.service";


const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true
    },
  },

  {
    path: 'authentication',
    component: AuthenticationComponent,
    pathMatch: 'full',
    canActivate: [LoggedInGuard, MultiFactorAuthenticationGuard],
    data: {
      isExtranet: true,
      fixedHeader: false,
      hideLayout: true,
    },
  },
  {
    path: '2fa/fingerprint',
    data: {
      isExtranet: true,
      hideFooter: true,
      hideIntranetHeader: true
    },
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./shared/fingerprint-authentication/fingerprint-authentication-routing.module').then(m => m.FingerprintAuthenticationRoutingModule),
  },
  {
    path: 'download-plugin',
    data: {
      isExtranet: true,
      hideIntranetHeader: true
    },
    canActivate: [],
    loadChildren: () => import('./modules/extranet/biometric-auth-plugin/biometric-auth-plugin.module').then(m => m.BiometricAuthPluginModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/intranet/intranet.module').then(
        (m) => m.IntranetModule
      ),
      // canActivate: [OtpGuard],
      canActivate: [MultiFactorAuthenticationGuard],
    data: {
      isExtranet: false
    },
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    data: {
      isExtranet: true
    },
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      isExtranet: false
    },
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
