/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { PortalUser } from './portal-user';


export interface HClone { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: HClone.StatusEnum;
    deactivatedBy?: PortalUser;
    clientId?: string;
    clientSecret?: string;
    setupCompleted?: boolean;
    name?: string;
    description?: string;
    publicKey?: string;
    keyExchangeCompleted?: boolean;
    numberOfRecordsForActivation?: number;
    adminFirstName?: string;
    adminLastName?: string;
    adminPhoneNumber?: string;
    adminEmail?: string;
    adminUin?: string;
    cbsCustomerAccountId?: string;
    approvalStatus?: HClone.ApprovalStatusEnum;
    address?: Address;
}
export namespace HClone {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'PENDING';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Pending: 'PENDING' as StatusEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum
    };
}


