/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccountMembershipPojo { 
    accountName?: string;
    accountCode?: string;
    accountId?: number;
    accountType?: AccountMembershipPojo.AccountTypeEnum;
    roles?: Set<string>;
    permissions?: Set<AccountMembershipPojo.PermissionsEnum>;
    otherData?: Array<object>;
}
export namespace AccountMembershipPojo {
    export type AccountTypeEnum = 'CONTROLLER' | 'SYSTEM_SUPPORT';
    export const AccountTypeEnum = {
        Controller: 'CONTROLLER' as AccountTypeEnum,
        SystemSupport: 'SYSTEM_SUPPORT' as AccountTypeEnum
    };
    export type PermissionsEnum = 'CREATE_USER' | 'UPDATE_USER' | 'VIEW_USER' | 'DEACTIVATE_USER' | 'UPDATE_SETTING' | 'VIEW_SETTING' | 'CREATE_CLONE_REQUEST' | 'CREATE_CLONE' | 'VIEW_CLONE' | 'APPROVE_CLONE_REQUEST' | 'VIEW_CLONE_CLIENT' | 'GENERATE_CLONE_LICENCE' | 'DOWNLOAD_CLONE_LICENCE' | 'VIEW_VERIFICATION_TRANSACTION' | 'VIEW_AUDIT_TRAIL';
    export const PermissionsEnum = {
        CreateUser: 'CREATE_USER' as PermissionsEnum,
        UpdateUser: 'UPDATE_USER' as PermissionsEnum,
        ViewUser: 'VIEW_USER' as PermissionsEnum,
        DeactivateUser: 'DEACTIVATE_USER' as PermissionsEnum,
        UpdateSetting: 'UPDATE_SETTING' as PermissionsEnum,
        ViewSetting: 'VIEW_SETTING' as PermissionsEnum,
        CreateCloneRequest: 'CREATE_CLONE_REQUEST' as PermissionsEnum,
        CreateClone: 'CREATE_CLONE' as PermissionsEnum,
        ViewClone: 'VIEW_CLONE' as PermissionsEnum,
        ApproveCloneRequest: 'APPROVE_CLONE_REQUEST' as PermissionsEnum,
        ViewCloneClient: 'VIEW_CLONE_CLIENT' as PermissionsEnum,
        GenerateCloneLicence: 'GENERATE_CLONE_LICENCE' as PermissionsEnum,
        DownloadCloneLicence: 'DOWNLOAD_CLONE_LICENCE' as PermissionsEnum,
        ViewVerificationTransaction: 'VIEW_VERIFICATION_TRANSACTION' as PermissionsEnum,
        ViewAuditTrail: 'VIEW_AUDIT_TRAIL' as PermissionsEnum
    };
}


