/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HClone } from './h-clone';


export interface HCloneActionRequestPojo { 
    approvalStatus?: HCloneActionRequestPojo.ApprovalStatusEnum;
    status?: HCloneActionRequestPojo.StatusEnum;
    type?: HCloneActionRequestPojo.TypeEnum;
    requestReason?: string;
    processingNote?: string;
    requestId?: string;
    processedAt?: string;
    approvedBy?: string;
    approvedByUserId?: string;
    createdBy?: string;
    createdAt?: string;
    createdByUserId?: string;
    createdByUserUin?: string;
    id?: number;
    dispatched?: boolean;
    acknowledged?: boolean;
    dispatchedAt?: string;
    acknowledgedAt?: string;
    completed?: boolean;
    completedAt?: string;
    hclone?: HClone;
}
export namespace HCloneActionRequestPojo {
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'PENDING';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Pending: 'PENDING' as StatusEnum
    };
    export type TypeEnum = 'CLEAR_DATA' | 'DISCONNECT' | 'DEACTIVATE' | 'REACTIVATE' | 'RECONNECT';
    export const TypeEnum = {
        ClearData: 'CLEAR_DATA' as TypeEnum,
        Disconnect: 'DISCONNECT' as TypeEnum,
        Deactivate: 'DEACTIVATE' as TypeEnum,
        Reactivate: 'REACTIVATE' as TypeEnum,
        Reconnect: 'RECONNECT' as TypeEnum
    };
}


