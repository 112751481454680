/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VerificationTransactionDto { 
    reasonForVerification: string;
    cloneTransactionId: string;
    verificationStatus?: VerificationTransactionDto.VerificationStatusEnum;
    identifierType: VerificationTransactionDto.IdentifierTypeEnum;
    identifier: string;
    verificationType?: VerificationTransactionDto.VerificationTypeEnum;
    verificationAccessLevel: VerificationTransactionDto.VerificationAccessLevelEnum;
    cloneVerificationTime: string;
    verificationClientClientId: string;
    customerAccountId: string;
    requestSourceIP: string;
    clientRequestId: string;
    errorMessage?: string;
    requestBody: string;
}
export namespace VerificationTransactionDto {
    export type VerificationStatusEnum = 'MATCH_FOUND' | 'MATCH_NOT_FOUND' | 'FAILED';
    export const VerificationStatusEnum = {
        MatchFound: 'MATCH_FOUND' as VerificationStatusEnum,
        MatchNotFound: 'MATCH_NOT_FOUND' as VerificationStatusEnum,
        Failed: 'FAILED' as VerificationStatusEnum
    };
    export type IdentifierTypeEnum = 'UIN' | 'TOKEN' | 'CREDENTIAL_NUMBER';
    export const IdentifierTypeEnum = {
        Uin: 'UIN' as IdentifierTypeEnum,
        Token: 'TOKEN' as IdentifierTypeEnum,
        CredentialNumber: 'CREDENTIAL_NUMBER' as IdentifierTypeEnum
    };
    export type VerificationTypeEnum = 'READ_ATTRIBUTE' | 'VERIFY_ID';
    export const VerificationTypeEnum = {
        ReadAttribute: 'READ_ATTRIBUTE' as VerificationTypeEnum,
        VerifyId: 'VERIFY_ID' as VerificationTypeEnum
    };
    export type VerificationAccessLevelEnum = 'LEVEL_ONE' | 'LEVEL_TWO' | 'LEVEL_THREE' | 'LEVEL_FOUR' | 'LEVEL_FIVE';
    export const VerificationAccessLevelEnum = {
        One: 'LEVEL_ONE' as VerificationAccessLevelEnum,
        Two: 'LEVEL_TWO' as VerificationAccessLevelEnum,
        Three: 'LEVEL_THREE' as VerificationAccessLevelEnum,
        Four: 'LEVEL_FOUR' as VerificationAccessLevelEnum,
        Five: 'LEVEL_FIVE' as VerificationAccessLevelEnum
    };
}


