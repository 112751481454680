/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VerificationApiClientDto { 
    id?: number;
    clientId?: string;
    customerAccountCode?: string;
    customerName?: string;
    clientName?: string;
    scopes?: Array<string>;
    accessLevel?: VerificationApiClientDto.AccessLevelEnum;
    status?: VerificationApiClientDto.StatusEnum;
}
export namespace VerificationApiClientDto {
    export type AccessLevelEnum = 'LEVEL_ONE' | 'LEVEL_TWO' | 'LEVEL_THREE' | 'LEVEL_FOUR' | 'LEVEL_FIVE';
    export const AccessLevelEnum = {
        One: 'LEVEL_ONE' as AccessLevelEnum,
        Two: 'LEVEL_TWO' as AccessLevelEnum,
        Three: 'LEVEL_THREE' as AccessLevelEnum,
        Four: 'LEVEL_FOUR' as AccessLevelEnum,
        Five: 'LEVEL_FIVE' as AccessLevelEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'PENDING';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Pending: 'PENDING' as StatusEnum
    };
}


