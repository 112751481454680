/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ActivityLogPojo { 
    actorEmail?: string;
    actorName?: string;
    timestamp?: string;
    action?: string;
    description?: string;
    userRole?: string;
    activityStatus?: ActivityLogPojo.ActivityStatusEnum;
    recipient?: string;
    phoneNumber?: string;
    ipAddress?: string;
    recipientEmail?: string;
}
export namespace ActivityLogPojo {
    export type ActivityStatusEnum = 'PENDING' | 'FAILED' | 'SUCCESSFUL';
    export const ActivityStatusEnum = {
        Pending: 'PENDING' as ActivityStatusEnum,
        Failed: 'FAILED' as ActivityStatusEnum,
        Successful: 'SUCCESSFUL' as ActivityStatusEnum
    };
}


