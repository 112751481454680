<div class="modal-header">
  <h5 class="modal-title"> {{modalHeader}} </h5>
  <button type="button" class="btn-close" (click)="close()" aria-label="Close"></button>
</div>
<div class="modal-body">
<!--  <div style="min-height: 150px;">-->
<!--    <ng-lottie [options]="{ path: './assets/lottie/failure.json', loop: false, autoplay: true }" width="150px"-->
<!--               height="150px" containerClass="mx-auto"></ng-lottie>-->
<!--  </div>-->
  <span>There is a pending request for this action. Kindly attend to it</span>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="close()"><span class="iconify icon"></span> Close</button>
</div>
