import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.css']
})
export class SkeletonLoaderComponent implements OnInit {

  @Input() classes: string = '';
  @Input() width: string = '';
  @Input() height: string = '';
  @Input() style: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  addCustomClasses(){
    return this.classes || '';
  }

  addCustomStyles(){
    let style = '';

    if (this.width) {
      style += ` width: ${this.width};`
    }
    if (this.height) {
      style += ` height: ${this.height};`
    }
    if (this.style) {
      style += ` ${this.style}`
    }

    return style;

  }

}
