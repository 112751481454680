/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerifyIDRequest } from './verify-id-request';


export interface HgsVerifyIDRequestDto { 
    identifier?: string;
    identifierType?: HgsVerifyIDRequestDto.IdentifierTypeEnum;
    cloneTransactionId?: string;
    verificationProofRequired?: boolean;
    query?: VerifyIDRequest;
    reasonForRequest?: string;
    hashedIdentifier?: string;
    cloneClientId?: string;
}
export namespace HgsVerifyIDRequestDto {
    export type IdentifierTypeEnum = 'UIN' | 'TOKEN' | 'CREDENTIAL_NUMBER';
    export const IdentifierTypeEnum = {
        Uin: 'UIN' as IdentifierTypeEnum,
        Token: 'TOKEN' as IdentifierTypeEnum,
        CredentialNumber: 'CREDENTIAL_NUMBER' as IdentifierTypeEnum
    };
}


