import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {SearchManager} from "../search/search-manager";

@Component({
  selector: 'page-size-five',
  templateUrl: './page-size-five.component.html',
  styleUrls: ['./page-size-five.component.css']
})


export class PageSizeFiveComponent implements OnInit{

  @Input() searchManager: SearchManager<any, any> | undefined;

  @Input() pageLengths: number[] = [5, 10, 25, 50, 100];

  @Output() pageLengthChanged = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    if (this.searchManager) {
      this.searchManager.itemsPerPage = this.pageLength;
    }
  }

  get pageLength() {
    // @ts-ignore
    return 5;
  }

  set pageLength(val) {
    if (this.searchManager) {
      this.searchManager.itemsPerPage = val;
      if (this.searchManager.list.length) {
        this.searchManager.reloadAndShowFirstPage();
      }
    }
    this.pageLengthChanged.emit(this.pageLength);
  }

}
