/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CloneClientDto } from '../model/models';
import { QueryResultsCloneClientDto } from '../model/models';
import { QueryResultsVerificationApiClientCloneDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface GetClientDetailsRequestParams {
    id: number;
}

export interface GetClonesForRequestParams {
    id: number;
    offset?: number;
    limit?: number;
}

export interface SearchVerificationApiClientRequestParams {
    lastUpdatedBy?: string;
    clientId?: string;
    customerAccountCode?: string;
    accessLevel?: 'LEVEL_ONE' | 'LEVEL_TWO' | 'LEVEL_THREE' | 'LEVEL_FOUR' | 'LEVEL_FIVE';
    lastUpdatedAt?: string;
    clientName?: string;
    customerAccountStatus?: 'ACTIVE' | 'INACTIVE' | 'PENDING';
    dateProcessed?: string;
    secret?: string;
    customerName?: string;
    deactivatedBy?: string;
    createdAt?: string;
    createdBy?: string;
    requestId?: string;
    scope?: string;
    dateDeactivated?: string;
    id?: number;
    scopes?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'PENDING';
    offset?: number;
    limit?: number;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class VerificationApiClientControllerService {

    protected basePath = 'http://hgs.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientDetails(requestParameters: GetClientDetailsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<CloneClientDto>;
    public getClientDetails(requestParameters: GetClientDetailsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<CloneClientDto>>;
    public getClientDetails(requestParameters: GetClientDetailsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<CloneClientDto>>;
    public getClientDetails(requestParameters: GetClientDetailsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getClientDetails.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<CloneClientDto>(`${this.configuration.basePath}/verification-api-clients/${encodeURIComponent(String(id))}/details`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClonesFor(requestParameters: GetClonesForRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsVerificationApiClientCloneDto>;
    public getClonesFor(requestParameters: GetClonesForRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsVerificationApiClientCloneDto>>;
    public getClonesFor(requestParameters: GetClonesForRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsVerificationApiClientCloneDto>>;
    public getClonesFor(requestParameters: GetClonesForRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getClonesFor.');
        }
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsVerificationApiClientCloneDto>(`${this.configuration.basePath}/verification-api-clients/${encodeURIComponent(String(id))}/clones`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchVerificationApiClient(requestParameters: SearchVerificationApiClientRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsCloneClientDto>;
    public searchVerificationApiClient(requestParameters: SearchVerificationApiClientRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsCloneClientDto>>;
    public searchVerificationApiClient(requestParameters: SearchVerificationApiClientRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsCloneClientDto>>;
    public searchVerificationApiClient(requestParameters: SearchVerificationApiClientRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const lastUpdatedBy = requestParameters.lastUpdatedBy;
        const clientId = requestParameters.clientId;
        const customerAccountCode = requestParameters.customerAccountCode;
        const accessLevel = requestParameters.accessLevel;
        const lastUpdatedAt = requestParameters.lastUpdatedAt;
        const clientName = requestParameters.clientName;
        const customerAccountStatus = requestParameters.customerAccountStatus;
        const dateProcessed = requestParameters.dateProcessed;
        const secret = requestParameters.secret;
        const customerName = requestParameters.customerName;
        const deactivatedBy = requestParameters.deactivatedBy;
        const createdAt = requestParameters.createdAt;
        const createdBy = requestParameters.createdBy;
        const requestId = requestParameters.requestId;
        const scope = requestParameters.scope;
        const dateDeactivated = requestParameters.dateDeactivated;
        const id = requestParameters.id;
        const scopes = requestParameters.scopes;
        const status = requestParameters.status;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (clientId !== undefined && clientId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientId, 'clientId');
        }
        if (customerAccountCode !== undefined && customerAccountCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerAccountCode, 'customerAccountCode');
        }
        if (accessLevel !== undefined && accessLevel !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>accessLevel, 'accessLevel');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (clientName !== undefined && clientName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>clientName, 'clientName');
        }
        if (customerAccountStatus !== undefined && customerAccountStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerAccountStatus, 'customerAccountStatus');
        }
        if (dateProcessed !== undefined && dateProcessed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateProcessed, 'dateProcessed');
        }
        if (secret !== undefined && secret !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>secret, 'secret');
        }
        if (customerName !== undefined && customerName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerName, 'customerName');
        }
        if (deactivatedBy !== undefined && deactivatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deactivatedBy, 'deactivatedBy');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (requestId !== undefined && requestId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requestId, 'requestId');
        }
        if (scope !== undefined && scope !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>scope, 'scope');
        }
        if (dateDeactivated !== undefined && dateDeactivated !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDeactivated, 'dateDeactivated');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (scopes !== undefined && scopes !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>scopes, 'scopes');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsCloneClientDto>(`${this.configuration.basePath}/verification-api-clients`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
