<div class="my-5 py-5">
    <div class="mb-4 pt-5 text-center">
        <a class="navbar-brand nav-logo mx-auto" [routerLink]="'/'">
            <svg xmlns="http://www.w3.org/2000/svg" height="60" fill="none" viewBox="0 0 162 44"
                 class="nav-logo w-auto">
                <path fill="currentColor"
                      d="M72.155 23.281v2.887H62.78V23.28h9.375Zm-8.277-7.315v17.961h-3.7v-17.96h3.7Zm10.917 0v17.961h-3.688v-17.96h3.688Zm10.745 3.072-4.885 14.889H76.72l6.673-17.96h2.504l-.357 3.07Zm4.058 14.889-4.897-14.89-.395-3.07h2.529l6.71 17.96h-3.947Zm-.222-6.686v2.899H79.89v-2.9h9.486Zm12.62-2.418v2.788h-6.97v-2.788h6.97Zm17.8-8.857v17.961h-3.701l-7.216-12.04v12.04h-3.701v-17.96h3.701l7.229 12.051V15.966h3.688Zm9.696 14.21 4.231-14.21h4.12l-6.254 17.961h-2.714l.617-3.75Zm-3.898-14.21 4.219 14.21.641 3.751h-2.738l-6.218-17.96h4.096Zm23.277 13.261c0-.32-.049-.608-.148-.863a1.626 1.626 0 0 0-.518-.716c-.255-.222-.612-.44-1.073-.654a15.127 15.127 0 0 0-1.789-.666 22.655 22.655 0 0 1-2.319-.888 9.544 9.544 0 0 1-1.924-1.147 5.175 5.175 0 0 1-1.308-1.542c-.312-.584-.468-1.263-.468-2.036 0-.748.164-1.426.493-2.035a4.688 4.688 0 0 1 1.382-1.579c.592-.444 1.291-.785 2.097-1.024a9.509 9.509 0 0 1 2.677-.358c1.324 0 2.479.239 3.466.716.987.477 1.752 1.13 2.294 1.961.551.83.827 1.78.827 2.85h-3.676c0-.526-.111-.987-.333-1.382a2.255 2.255 0 0 0-.987-.95c-.436-.23-.987-.345-1.653-.345-.642 0-1.176.099-1.604.296-.427.19-.748.448-.962.777-.214.32-.321.683-.321 1.086 0 .304.074.58.222.826.157.247.387.477.691.691.304.214.679.415 1.123.604.444.19.958.375 1.542.556.978.296 1.838.629 2.578.999.748.37 1.373.785 1.875 1.246.502.46.88.983 1.135 1.566.255.584.382 1.246.382 1.986 0 .782-.152 1.48-.456 2.097a4.311 4.311 0 0 1-1.32 1.567c-.576.428-1.263.753-2.06.975-.798.222-1.69.333-2.677.333-.888 0-1.764-.115-2.628-.346a7.994 7.994 0 0 1-2.356-1.073 5.403 5.403 0 0 1-1.677-1.826c-.42-.74-.63-1.616-.63-2.627h3.714c0 .56.086 1.032.259 1.419.172.386.415.698.727.937.321.238.699.411 1.135.518.444.107.93.16 1.456.16.641 0 1.168-.09 1.579-.27.419-.182.728-.433.925-.753.206-.321.308-.683.308-1.086Z"/>
                <path fill="#C6D4F2"
                      d="M51.983 33.276c0-3.147-1.68-5.944-4.114-7.43a8.59 8.59 0 0 0-1.425-.664c.842-.247 2.37-1.266 3.104-2.395 3.526 2.185 5.793 6.206 5.793 10.49h-3.358ZM10.59 29.692c-1.344 1.224-2.267 3.147-2.267 5.245H4.964c0-3.06 1.26-5.944 3.443-7.867.336-.35.755-.7 1.175-.961l.42-1.399c1.595-5.856 6.717-9.877 12.51-9.877.588 0 1.176.088 1.848.175.083.087.167.087.251.087 2.435-3.933 6.633-6.38 11.167-6.38-1.058.468-2.194 2.77-2.63 3.863-2.557.732-4.71 2.507-6.102 4.877 2.939 2.01 4.87 5.507 4.87 9.44v.088c-.504-3.497-2.687-6.381-5.625-7.78-.756-.436-1.596-.611-2.52-.786-.42 0-.839-.088-1.343-.088-4.45 0-8.144 3.147-9.32 7.343 3.107.35 5.794 2.272 7.221 4.982h-.084c-1.175-1.66-3.106-2.71-5.29-2.71-.755 0-1.51.175-2.182.437-.84.262-1.511.7-2.183 1.311Z"/>
                <path fill="currentColor"
                      d="M47.062 11.204c-1.088 0-1.974.922-1.974 2.056 0 .344.09.663.233.949l-.735.892a2.546 2.546 0 0 0-1.473-.47c-.487 0-.938.147-1.33.387l-.931-.969a2.44 2.44 0 0 0 .286-1.132c0-1.323-1.034-2.399-2.304-2.399-1.27 0-2.305 1.076-2.305 2.4 0 1.322 1.034 2.398 2.305 2.398a2.2 2.2 0 0 0 1.087-.298l.931.97c-.241.418-.37.896-.373 1.384 0 .683.25 1.301.649 1.782l-1.114 1.16.016.017a1.887 1.887 0 0 0-.867-.218c-1.09 0-1.975.922-1.975 2.056 0 1.133.886 2.056 1.975 2.056s1.975-.923 1.975-2.056c0-.326-.08-.63-.21-.903l.017.017 1.286-1.34c.277.104.57.17.882.17 1.452 0 2.633-1.23 2.633-2.741a2.803 2.803 0 0 0-.29-1.224l.826-1.002c.24.109.503.17.78.17 1.09 0 1.975-.923 1.975-2.056 0-1.134-.886-2.056-1.975-2.056Zm-7.9 11.65a.645.645 0 0 1-.465-.2.7.7 0 0 1-.193-.485.7.7 0 0 1 .193-.485.648.648 0 0 1 .466-.2c.174 0 .342.072.465.2a.7.7 0 0 1 .193.485.7.7 0 0 1-.193.485.646.646 0 0 1-.465.2Zm-1.316-9.937c0-.567.443-1.028.988-1.028.544 0 .987.461.987 1.028s-.443 1.028-.987 1.028c-.545 0-.988-.461-.988-1.028Zm5.267 5.825c-.727 0-1.317-.614-1.317-1.37 0-.756.59-1.371 1.317-1.371.726 0 1.316.615 1.316 1.37 0 .757-.59 1.371-1.316 1.371Zm3.95-4.797a.645.645 0 0 1-.466-.2.7.7 0 0 1-.193-.485.7.7 0 0 1 .193-.485.644.644 0 0 1 .931 0 .7.7 0 0 1 .193.485.7.7 0 0 1-.193.484.645.645 0 0 1-.466.201Z"/>
            </svg>
        </a>
    </div>
    <p class="fs-28 m-0 mb-1 text-center text-header fw-bold">Two Factor Authentication</p>
    <div *ngIf="infoMessage" class="text-center my-2">{{infoMessage}}</div>
    <div style="max-width: 600px;" class="mt-3 p-4 mx-auto w-100 card-bg">
        <alert *ngIf="successMessage" [type]="'success'" class="col-md-12">{{successMessage}}</alert>
        <alert *ngIf="errorMessage" [type]="'danger'" class="col-md-12">{{errorMessage}}</alert>
        <form [formGroup]="form" (validSubmit)="verifyOTP()">
            <div class="mb-2">
<!--                <div class="form-group w-100 mb-2">-->
<!--                    <div class="d-flex align-items-center justify-content-between">-->
<!--                        <label>Enter OTP</label>-->
<!--                        <span *ngIf="disableResendButton" class="fs-13 text-end"> Resend in {{optCountDown}}-->
<!--                            seconds </span>-->
<!--                    </div>-->
<!--                    <div class="input-group mb-3 w-100">-->
<!--                        <input type="tel" pattern="[0-9]*" (keydown)="preventForeignKeys($event)" class="form-control"-->
<!--                               placeholder="Enter OTP" formControlName="otp" [maxLength]="otpLength">-->
<!--                        <span class="input-group-text" id="basic-addon2">-->
<!--                            <div class="btn-group" role="group" aria-label="Button group with nested dropdown">-->
<!--                                <button type="button" class="btn-unset-all text-link fs-15 px-2"-->
<!--                                        [disabled]="disableResendButton" (click)="resendOTP(this.deliveryMode)">-->
<!--                                  <span class="iconify" data-icon="system-uicons:reset-forward"> </span> Resend-->
<!--                                </button>-->
<!--                                <button type="button"-->
<!--                                        class="btn-unset-all text-link fs-15 px-2 dropdown-toggle"-->
<!--                                        [disabled]="disableResendButton"-->
<!--                                        data-bs-toggle="dropdown"-->
<!--                                        aria-expanded="false"-->
<!--                                        style="background-color: #f2f3f5">-->
<!--                                </button>-->
<!--                                <ul class="dropdown-menu" style="cursor: pointer">-->
<!--                                    <li *ngIf="this.deliveryMode != 'EMAIL'">-->
<!--                                        <a class="dropdown-item" data-bs-toggle="dropdown"-->
<!--                                           (click)="switchDeliveryMode('EMAIL')">-->
<!--                                                <span class="iconify"-->
<!--                                                      data-icon="material-symbols:alternate-email"-->
<!--                                                      style="color: #1551aa;">-->
<!--                                                </span>-->
<!--                                         Via Email-->
<!--                                        </a>-->
<!--                                    </li>-->
<!--                                  <li *ngIf="this.deliveryMode != 'SMS'">-->
<!--                                      <a class="dropdown-item" data-bs-toggle="dropdown"-->
<!--                                         (click)="switchDeliveryMode('SMS')">-->
<!--                                        <span class="iconify" data-icon="la:sms" style="color: #1551aa;"></span>-->
<!--                                      Via SMS-->
<!--                                        </a>-->
<!--                                  </li>-->
<!--                                </ul>-->
<!--                            </div>-->
<!--                    </span>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="mb-2">
                    <div class="form-group w-100">
                        <label>Enter OTP</label>
                        <div class="input-group mb-3  w-100">
                            <input type="tel" pattern="[0-9]*" (keydown)="preventForeignKeys($event)" type="text"
                                   class="form-control" placeholder="Enter OTP" formControlName="otp"
                                   [maxLength]="otpLength" aria-label="Text input with segmented dropdown button">
                            <span class="input-group-text pe-1 " id="basic-addon2">
                                    <button class="btn-unset-all text-link fs-15" [disabled]="disableResendButton"
                                            (click)="resendOTP(this.deliveryMode)"> Resend </button>

                                    <button [disabled]="disableResendButton" type="button"
                                            class="btn btn-unset-all text-link dropdown-toggle px-2"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                        <span class="visually-hidden">Toggle Dropdown</span>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" style="cursor: pointer">
                                    <li *ngIf="this.deliveryMode != 'EMAIL'">
                                        <a class="dropdown-item" data-bs-toggle="dropdown"
                                           (click)="switchDeliveryMode('EMAIL')">
                                                <span class="iconify"
                                                      data-icon="material-symbols:alternate-email"
                                                      style="color: #1551aa;">
                                                </span>
                                         Via Email
                                        </a>
                                    </li>
                                  <li *ngIf="this.deliveryMode != 'SMS'">
                                      <a class="dropdown-item" data-bs-toggle="dropdown"
                                         (click)="switchDeliveryMode('SMS')">
                                        <span class="iconify" data-icon="la:sms" style="color: #1551aa;"></span>
                                      Via SMS
                                        </a>
                                  </li>
                                    </ul>
                                </span>

                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-start">
                    <span *ngIf="disableResendButton" class="fs-13 text-end"> Resend in {{optCountDown}} seconds </span>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary" [disabled]="verifyingOtp">Proceed</button>
                </div>
            </div>
        </form>

    </div>
</div>
