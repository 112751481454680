/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HcloneYTDSummaryPojo } from './hclone-ytd-summary-pojo';
import { PendingRecordsPojo } from './pending-records-pojo';


export interface AnalyticsSummaryPojo { 
    totalCountOfActiveActivations?: number;
    totalCountOfUndergoingActivations?: number;
    lastCloneSyncTime?: string;
    totalActivated?: number;
    controllerRecords?: number;
    totalPopulationRegistry?: number;
    maximumDifference?: number;
    lastCloneActivatedRateOfRecords?: number;
    lastCloneActivatedStarDate?: string;
    lastActivatedCloneEstimatedEndDate?: string;
    pendingRecordsPojos?: Array<PendingRecordsPojo>;
    hcloneYTDSummaryPojoList?: Array<HcloneYTDSummaryPojo>;
}

