/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationRequestDto } from './verification-request-dto';


export interface QueryResultsVerificationRequestDto { 
    limit?: number;
    offset?: number;
    total?: number;
    results?: Array<VerificationRequestDto>;
    empty?: boolean;
}

