import {Component, Input} from '@angular/core';
import {SearchManager} from "../search/search-manager";

@Component({
    selector: 'app-total-records',
    templateUrl: './total-records.component.html',
    styleUrls: ['./total-records.component.css']
})
export class TotalRecordsComponent {
    @Input()
    searchManager: SearchManager<any, any> | undefined;

    @Input()
    title = 'Total records';
}
