import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.css']
})
export class ToggleSwitchComponent implements OnInit {

  @Input() defaultValue:boolean;
  @Output() switchValue = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  set checked(value: boolean | undefined) {
    this.switchValue.emit(value);
  }

  get checked(){
    return this.defaultValue || false;
  }

}
