/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ActivityLogSearchFilter { 
    offset?: number;
    limit?: number;
    order?: ActivityLogSearchFilter.OrderEnum;
    orderColumn?: string;
    userName?: string;
    phoneNumber?: string;
    recipient?: string;
    userType?: string;
    activity?: ActivityLogSearchFilter.ActivityEnum;
    status?: ActivityLogSearchFilter.StatusEnum;
    action?: ActivityLogSearchFilter.ActionEnum;
    startDate?: string;
    endDate?: string;
    ipAddress?: string;
    description?: string;
}
export namespace ActivityLogSearchFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
    export type ActivityEnum = 'LOGIN' | 'LOGOUT' | 'VIEW_CLONE_DETAILS' | 'VIEW_NODE_REQUEST' | 'CREATE_NODE_REQUEST' | 'APPROVE_NODE_ACTION_REQUEST' | 'DISAPPROVE_NODE_ACTION_REQUEST' | 'UPDATE_SETTING';
    export const ActivityEnum = {
        Login: 'LOGIN' as ActivityEnum,
        Logout: 'LOGOUT' as ActivityEnum,
        ViewCloneDetails: 'VIEW_CLONE_DETAILS' as ActivityEnum,
        ViewNodeRequest: 'VIEW_NODE_REQUEST' as ActivityEnum,
        CreateNodeRequest: 'CREATE_NODE_REQUEST' as ActivityEnum,
        ApproveNodeActionRequest: 'APPROVE_NODE_ACTION_REQUEST' as ActivityEnum,
        DisapproveNodeActionRequest: 'DISAPPROVE_NODE_ACTION_REQUEST' as ActivityEnum,
        UpdateSetting: 'UPDATE_SETTING' as ActivityEnum
    };
    export type StatusEnum = 'PENDING' | 'FAILED' | 'SUCCESSFUL';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        Failed: 'FAILED' as StatusEnum,
        Successful: 'SUCCESSFUL' as StatusEnum
    };
    export type ActionEnum = 'LOGIN' | 'LOGOUT' | 'VIEW_CLONE_DETAILS' | 'VIEW_NODE_REQUEST' | 'CREATE_NODE_REQUEST' | 'APPROVE_NODE_ACTION_REQUEST' | 'DISAPPROVE_NODE_ACTION_REQUEST' | 'UPDATE_SETTING';
    export const ActionEnum = {
        Login: 'LOGIN' as ActionEnum,
        Logout: 'LOGOUT' as ActionEnum,
        ViewCloneDetails: 'VIEW_CLONE_DETAILS' as ActionEnum,
        ViewNodeRequest: 'VIEW_NODE_REQUEST' as ActionEnum,
        CreateNodeRequest: 'CREATE_NODE_REQUEST' as ActionEnum,
        ApproveNodeActionRequest: 'APPROVE_NODE_ACTION_REQUEST' as ActionEnum,
        DisapproveNodeActionRequest: 'DISAPPROVE_NODE_ACTION_REQUEST' as ActionEnum,
        UpdateSetting: 'UPDATE_SETTING' as ActionEnum
    };
}


