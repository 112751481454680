/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CredentialData { 
    credentialId?: string;
    status?: CredentialData.StatusEnum;
    statusOther?: string;
    credentialNumber?: string;
    personId?: string;
    credentialType?: string;
    issuedDate?: string;
    expiryDate?: string;
    serialNumber?: string;
    issuingAuthority?: string;
    issuingPlace?: string;
}
export namespace CredentialData {
    export type StatusEnum = 'NEW' | 'ACTIVE' | 'SUSPENDED' | 'REVOKED' | 'OTHER';
    export const StatusEnum = {
        New: 'NEW' as StatusEnum,
        Active: 'ACTIVE' as StatusEnum,
        Suspended: 'SUSPENDED' as StatusEnum,
        Revoked: 'REVOKED' as StatusEnum,
        Other: 'OTHER' as StatusEnum
    };
}


