import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    // Format the date to get the day, month, year, and time separately
    const day = super.transform(value, 'd');
    const monthYear = super.transform(value, 'MMMM y');
    const time = super.transform(value, 'h:mm a');

    // Add the ordinal suffix to the day
    const dayWithSuffix = this.addOrdinalSuffix(day);

    // Combine parts to form the desired format
    return `${dayWithSuffix}, ${monthYear} ${time} GMT`;
  }

  addOrdinalSuffix(day: string | null): string {
    if (!day) return '';
    const dayNumber = parseInt(day, 10);
    if (isNaN(dayNumber)) return day;

    const suffixes = ['th', 'st', 'nd', 'rd'];
    const value = dayNumber % 100;
    return day + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
  }
}
