/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewHcloneActionRequestDto { 
    reason: string;
    type: NewHcloneActionRequestDto.TypeEnum;
    cloneId: number;
}
export namespace NewHcloneActionRequestDto {
    export type TypeEnum = 'CLEAR_DATA' | 'DISCONNECT' | 'DEACTIVATE' | 'REACTIVATE' | 'RECONNECT';
    export const TypeEnum = {
        ClearData: 'CLEAR_DATA' as TypeEnum,
        Disconnect: 'DISCONNECT' as TypeEnum,
        Deactivate: 'DEACTIVATE' as TypeEnum,
        Reactivate: 'REACTIVATE' as TypeEnum,
        Reconnect: 'RECONNECT' as TypeEnum
    };
}


