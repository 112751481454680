/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface HclonePersonDataSearchFilter { 
    offset?: number;
    limit?: number;
    order?: HclonePersonDataSearchFilter.OrderEnum;
    orderColumn?: string;
    createdAfter?: string;
    createdBefore?: string;
    nin?: string;
    cloneName?: string;
    transactionReference?: string;
}
export namespace HclonePersonDataSearchFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
}


