/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VerificationRequestDto { 
    verificationType?: VerificationRequestDto.VerificationTypeEnum;
    cloneTransactionId?: string;
    idmsVerificationTransactionRef?: string;
    accessLevel?: VerificationRequestDto.AccessLevelEnum;
    verificationStatus?: VerificationRequestDto.VerificationStatusEnum;
    identifier?: string;
    identifierType?: VerificationRequestDto.IdentifierTypeEnum;
    clientRequestId?: string;
    clientSystemName?: string;
    biometricRequest?: boolean;
    fulfillmentReconciledAt?: string;
    requestDataSizeInBytes?: number;
    responseReceivedAt?: string;
    responseDataSizeInBytes?: number;
    sourceIp?: string;
    verificationProofRequired?: boolean;
    reasonForRequest?: string;
    createdAt?: string;
    syncStatus?: VerificationRequestDto.SyncStatusEnum;
    errorMessage?: string;
    hcloneId?: string;
    hcloneName?: string;
    cloneClientName?: string;
    cloneClientCustomerName?: string;
    requestBody?: string;
}
export namespace VerificationRequestDto {
    export type VerificationTypeEnum = 'READ_ATTRIBUTE' | 'VERIFY_ID';
    export const VerificationTypeEnum = {
        ReadAttribute: 'READ_ATTRIBUTE' as VerificationTypeEnum,
        VerifyId: 'VERIFY_ID' as VerificationTypeEnum
    };
    export type AccessLevelEnum = 'LEVEL_ONE' | 'LEVEL_TWO' | 'LEVEL_THREE' | 'LEVEL_FOUR' | 'LEVEL_FIVE';
    export const AccessLevelEnum = {
        One: 'LEVEL_ONE' as AccessLevelEnum,
        Two: 'LEVEL_TWO' as AccessLevelEnum,
        Three: 'LEVEL_THREE' as AccessLevelEnum,
        Four: 'LEVEL_FOUR' as AccessLevelEnum,
        Five: 'LEVEL_FIVE' as AccessLevelEnum
    };
    export type VerificationStatusEnum = 'MATCH_FOUND' | 'MATCH_NOT_FOUND' | 'FAILED';
    export const VerificationStatusEnum = {
        MatchFound: 'MATCH_FOUND' as VerificationStatusEnum,
        MatchNotFound: 'MATCH_NOT_FOUND' as VerificationStatusEnum,
        Failed: 'FAILED' as VerificationStatusEnum
    };
    export type IdentifierTypeEnum = 'UIN' | 'TOKEN';
    export const IdentifierTypeEnum = {
        Uin: 'UIN' as IdentifierTypeEnum,
        Token: 'TOKEN' as IdentifierTypeEnum
    };
    export type SyncStatusEnum = 'SENT' | 'FULFILLED';
    export const SyncStatusEnum = {
        Sent: 'SENT' as SyncStatusEnum,
        Fulfilled: 'FULFILLED' as SyncStatusEnum
    };
}


