import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-reason-for-disapproval-dialog',
  templateUrl: './reason-for-disapproval-dialog.component.html',
  styleUrls: ['./reason-for-disapproval-dialog.component.css']
})
export class ReasonForDisapprovalDialogComponent implements OnInit {

  @Input() infoMessage:string = 'Provide a reason for this action';
  @Input() inputLabel:string = 'Enter reason for deactivation';
  @Input() modalHeader:string = 'Deactivate';
  @Input() buttonLabel:string = 'Deactivate';
  @Input() buttonClass:string = 'btn-danger';
  @Input() buttonIcon:string = 'fluent:flash-off-24-regular';

  @Input() onDisapprove: () => void;

  @Output() onSuccessEmitter = new EventEmitter<string>();

  disapproveForm: FormGroup;
  errorMessage: string = ""
  isLoading = false;

  constructor(
    private bsModalRef: BsModalRef,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.disapproveForm = this.formBuilder.group({
      reason: ['', Validators.required]
    })
    this.disapproveForm.valueChanges.subscribe(() => {
      this.errorMessage = "";
    })
  }

  close(): void {
    this.onSuccessEmitter.emit();
    this.bsModalRef.hide();
  }

  disapprove(){
    if (this.disapproveForm.invalid) {
      this.errorMessage = "Reason for request is required!"
      return;
    }
    this.isLoading = true;
    this.onSuccessEmitter.emit(this.disapproveForm.get('reason')?.value);
    this.onDisapprove();
  }

}
