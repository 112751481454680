/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ActivityLogDto { 
    username?: string;
    action: ActivityLogDto.ActionEnum;
    userId?: string;
}
export namespace ActivityLogDto {
    export type ActionEnum = 'LOGIN' | 'LOGOUT' | 'VIEW_CLONE_DETAILS' | 'VIEW_NODE_REQUEST' | 'CREATE_NODE_REQUEST' | 'APPROVE_NODE_ACTION_REQUEST' | 'DISAPPROVE_NODE_ACTION_REQUEST' | 'UPDATE_SETTING';
    export const ActionEnum = {
        Login: 'LOGIN' as ActionEnum,
        Logout: 'LOGOUT' as ActionEnum,
        ViewCloneDetails: 'VIEW_CLONE_DETAILS' as ActionEnum,
        ViewNodeRequest: 'VIEW_NODE_REQUEST' as ActionEnum,
        CreateNodeRequest: 'CREATE_NODE_REQUEST' as ActionEnum,
        ApproveNodeActionRequest: 'APPROVE_NODE_ACTION_REQUEST' as ActionEnum,
        DisapproveNodeActionRequest: 'DISAPPROVE_NODE_ACTION_REQUEST' as ActionEnum,
        UpdateSetting: 'UPDATE_SETTING' as ActionEnum
    };
}


