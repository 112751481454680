/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HCloneManagementRecordPojo } from './h-clone-management-record-pojo';


export interface DashboardSummaryPojo { 
    totalCountOfActiveClones?: number;
    totalCountOfDeactivatedClones?: number;
    lastCloneSyncTime?: string;
    totalCloneUndergoingActivation?: number;
    totalActivated?: number;
    lastCloneActivationTime?: string;
    cloneManagementRecords?: Array<HCloneManagementRecordPojo>;
    controllerRecords?: number;
    totalPopulationRegistry?: number;
    maximumDifference?: number;
    lastChecksumTime?: string;
    totalEMERecordsForToday?: number;
}

