import {Component, Input, OnInit} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {AuthenticationService} from "../../../shared/authentication/authentication-service.service";

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.css']
})
export class SiteHeaderComponent implements OnInit {
  @Input()
  user: any;
  routeData: any;

  constructor(private router: Router, route: ActivatedRoute, private authenticationService: AuthenticationService) {
    router.events.forEach(e => {
      if (e instanceof NavigationEnd) {
        this.routeData = route?.root?.firstChild?.snapshot?.data;
      }
    })
  }

  ngOnInit(): void {

  }

  async login() {
    this.authenticationService.clearOtp();
    this.authenticationService.login({
      redirectUri: window.location.origin + '/dashboard',
      prompt: 'login'

    },);
  }

  async dashboard() {
    this.router.navigateByUrl('/dashboard')
  }

  async logout() {
    await this.authenticationService.logout().subscribe(value => {
      this.authenticationService.clearOtp();
    })
    // await this.keycloak.getKeycloakInstance().logout({})
  }

  goToDashboard(){
    this.router.navigate(['/dashboard'])
  }

  setHeaderClass() {
    let headerClass = '';

    if (this.routeData?.fixedHeader) {
      headerClass += ' header-fixed fixed-on-scroll'
    }
    if (!(this.routeData?.isExtranet)) {
      headerClass += ' intranet-nav'
    }

    return headerClass;
  }
}
