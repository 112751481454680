import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {LottieModule} from 'ngx-lottie';

import {UnderscoreToSpacePipe} from './pipes/underscore-to-space.pipe';
import {TimeOnlyPipe} from './pipes/time-only.pipe';
import {DateOnlyPipe} from './pipes/date-only.pipe';
import {DatetimePipe} from './pipes/datetime.pipe';
import {RemoveUnderscoresPipe} from './pipes/remove-underscores.pipe';
import {NumberStringPipe} from './pipes/number-string.pipe';
import {TruncatePipe} from './pipes/truncate.pipe';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {FullPageLoaderComponent} from './components/loaders/full-page-loader/full-page-loader.component';
import {AmountPipe} from "./pipes/amount.pipe";
import {FullDateTimePipe} from './pipes/full-date-time.pipe';
import {LoaderComponent} from './components/loaders/loader/loader.component';
import {ImageCropperModule} from "ngx-image-cropper";
import {SkeletonLoaderComponent} from './components/loaders/skeleton-loader/skeleton-loader.component';
import {SuccessDialogComponent} from './components/dialogs/success-dialog/success-dialog.component';
import {
  ReasonForDisapprovalDialogComponent
} from './components/dialogs/reason-for-disapproval-dialog/reason-for-disapproval-dialog.component';
import {ConfirmationDialogComponent} from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {ToggleSwitchComponent} from './components/toggle-switch/toggle-switch.component';
import {ShortestDatePipe} from "./pipes/shortest-date.pipe";
import {SplitCamelCasePipe} from './pipes/split-camel-case.pipe';
import {AlertModule} from "ngx-bootstrap/alert";
import {
  PendingRequestErrorModalComponent
} from './components/dialogs/pending-request-error-modal/pending-request-error-modal.component';
import {TotalRecordsComponent} from "./total-records/total-records.component";
import {CustomDatePipe} from "./pipes/custom-date.pipe";
import {FingerprintAuthenticationModule} from "./fingerprint-authentication/fingerprint-authentication.module";

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}

@NgModule({
  declarations: [
    UnderscoreToSpacePipe,
    TimeOnlyPipe,
    DateOnlyPipe,
    AmountPipe,
    DatetimePipe,
    RemoveUnderscoresPipe,
    NumberStringPipe,
    LoaderComponent,
    FullPageLoaderComponent,
    TruncatePipe,
    FullDateTimePipe,
    SplitCamelCasePipe,
    SkeletonLoaderComponent,
    SuccessDialogComponent,
    ReasonForDisapprovalDialogComponent,
    ConfirmationDialogComponent,
    ToggleSwitchComponent,
    ShortestDatePipe,
    PendingRequestErrorModalComponent,
    TotalRecordsComponent,
    CustomDatePipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ImageCropperModule,
    LottieModule.forRoot({player: playerFactory}),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgBootstrapFormValidationModule.forRoot(),
    BsDropdownModule.forRoot(),
    AlertModule
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    ProgressbarModule,
    TooltipModule,
    PaginationModule,
    BsDatepickerModule,
    UnderscoreToSpacePipe,
    TimeOnlyPipe,
    DateOnlyPipe,
    DatetimePipe,
    RemoveUnderscoresPipe,
    SplitCamelCasePipe,
    NumberStringPipe,
    TruncatePipe,
    BsDropdownModule,
    AmountPipe,
    NgBootstrapFormValidationModule,
    FullDateTimePipe,
    LoaderComponent,
    FullPageLoaderComponent,
    SkeletonLoaderComponent,
    ToggleSwitchComponent,
    ShortestDatePipe,
    TotalRecordsComponent,
    CustomDatePipe
  ], schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
}
