/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { QueryResultsHnodeActivityLogPojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface SearchHnodeActivityLogsRequestParams {
    id: number;
    lastUpdatedAt?: string;
    auditTransactionId?: string;
    serverEventTime?: string;
    description?: string;
    actorEmail?: string;
    actorName?: string;
    deactivatedBy?: string;
    recordId?: string;
    externalReferenceId?: string;
    createdAt?: string;
    entityName?: string;
    activityStatus?: 'PENDING' | 'FAILED' | 'SUCCESSFUL';
    recipientEmail?: string;
    actorUUID?: string;
    actorMaskedUIN?: string;
    lastUpdatedBy?: string;
    resource?: string;
    recordType?: string;
    actorRole?: string;
    ipAddress?: string;
    userAgent?: string;
    eventType?: string;
    resourceUri?: string;
    actorEventTime?: string;
    attemptedAttack?: boolean;
    hNode?: string;
    lastLoginAt?: string;
    recipientUUID?: string;
    authenticationMethod?: string;
    systemCode?: string;
    createdBy?: string;
    eventStatus?: string;
    actorPermission?: string;
    dateDeactivated?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'PENDING';
    offset?: number;
    endDate?: string;
    limit?: number;
    startDate?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class RepatriatedActivityLogSearchControllerService {

    protected basePath = 'http://hgs.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHnodeActivityType(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<string>>;
    public getHnodeActivityType(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<string>>>;
    public getHnodeActivityType(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<string>>>;
    public getHnodeActivityType(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/hnode-activity-logs/activity`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchHnodeActivityLogs(requestParameters: SearchHnodeActivityLogsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsHnodeActivityLogPojo>;
    public searchHnodeActivityLogs(requestParameters: SearchHnodeActivityLogsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsHnodeActivityLogPojo>>;
    public searchHnodeActivityLogs(requestParameters: SearchHnodeActivityLogsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsHnodeActivityLogPojo>>;
    public searchHnodeActivityLogs(requestParameters: SearchHnodeActivityLogsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling searchHnodeActivityLogs.');
        }
        const lastUpdatedAt = requestParameters.lastUpdatedAt;
        const auditTransactionId = requestParameters.auditTransactionId;
        const serverEventTime = requestParameters.serverEventTime;
        const description = requestParameters.description;
        const actorEmail = requestParameters.actorEmail;
        const actorName = requestParameters.actorName;
        const deactivatedBy = requestParameters.deactivatedBy;
        const recordId = requestParameters.recordId;
        const externalReferenceId = requestParameters.externalReferenceId;
        const createdAt = requestParameters.createdAt;
        const entityName = requestParameters.entityName;
        const activityStatus = requestParameters.activityStatus;
        const recipientEmail = requestParameters.recipientEmail;
        const actorUUID = requestParameters.actorUUID;
        const actorMaskedUIN = requestParameters.actorMaskedUIN;
        const lastUpdatedBy = requestParameters.lastUpdatedBy;
        const resource = requestParameters.resource;
        const recordType = requestParameters.recordType;
        const actorRole = requestParameters.actorRole;
        const ipAddress = requestParameters.ipAddress;
        const userAgent = requestParameters.userAgent;
        const eventType = requestParameters.eventType;
        const resourceUri = requestParameters.resourceUri;
        const actorEventTime = requestParameters.actorEventTime;
        const attemptedAttack = requestParameters.attemptedAttack;
        const hNode = requestParameters.hNode;
        const lastLoginAt = requestParameters.lastLoginAt;
        const recipientUUID = requestParameters.recipientUUID;
        const authenticationMethod = requestParameters.authenticationMethod;
        const systemCode = requestParameters.systemCode;
        const createdBy = requestParameters.createdBy;
        const eventStatus = requestParameters.eventStatus;
        const actorPermission = requestParameters.actorPermission;
        const dateDeactivated = requestParameters.dateDeactivated;
        const status = requestParameters.status;
        const offset = requestParameters.offset;
        const endDate = requestParameters.endDate;
        const limit = requestParameters.limit;
        const startDate = requestParameters.startDate;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (auditTransactionId !== undefined && auditTransactionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>auditTransactionId, 'auditTransactionId');
        }
        if (serverEventTime !== undefined && serverEventTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>serverEventTime, 'serverEventTime');
        }
        if (description !== undefined && description !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>description, 'description');
        }
        if (actorEmail !== undefined && actorEmail !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>actorEmail, 'actorEmail');
        }
        if (actorName !== undefined && actorName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>actorName, 'actorName');
        }
        if (deactivatedBy !== undefined && deactivatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deactivatedBy, 'deactivatedBy');
        }
        if (recordId !== undefined && recordId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>recordId, 'recordId');
        }
        if (externalReferenceId !== undefined && externalReferenceId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>externalReferenceId, 'externalReferenceId');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (entityName !== undefined && entityName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>entityName, 'entityName');
        }
        if (activityStatus !== undefined && activityStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>activityStatus, 'activityStatus');
        }
        if (recipientEmail !== undefined && recipientEmail !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>recipientEmail, 'recipientEmail');
        }
        if (actorUUID !== undefined && actorUUID !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>actorUUID, 'actorUUID');
        }
        if (actorMaskedUIN !== undefined && actorMaskedUIN !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>actorMaskedUIN, 'actorMaskedUIN');
        }
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (resource !== undefined && resource !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resource, 'resource');
        }
        if (recordType !== undefined && recordType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>recordType, 'recordType');
        }
        if (actorRole !== undefined && actorRole !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>actorRole, 'actorRole');
        }
        if (ipAddress !== undefined && ipAddress !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>ipAddress, 'ipAddress');
        }
        if (userAgent !== undefined && userAgent !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userAgent, 'userAgent');
        }
        if (eventType !== undefined && eventType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>eventType, 'eventType');
        }
        if (resourceUri !== undefined && resourceUri !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resourceUri, 'resourceUri');
        }
        if (actorEventTime !== undefined && actorEventTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>actorEventTime, 'actorEventTime');
        }
        if (attemptedAttack !== undefined && attemptedAttack !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>attemptedAttack, 'attemptedAttack');
        }
        if (hNode !== undefined && hNode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hNode, 'HNode');
        }
        if (lastLoginAt !== undefined && lastLoginAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastLoginAt, 'lastLoginAt');
        }
        if (recipientUUID !== undefined && recipientUUID !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>recipientUUID, 'recipientUUID');
        }
        if (authenticationMethod !== undefined && authenticationMethod !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>authenticationMethod, 'authenticationMethod');
        }
        if (systemCode !== undefined && systemCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>systemCode, 'systemCode');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (eventStatus !== undefined && eventStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>eventStatus, 'eventStatus');
        }
        if (actorPermission !== undefined && actorPermission !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>actorPermission, 'actorPermission');
        }
        if (dateDeactivated !== undefined && dateDeactivated !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDeactivated, 'dateDeactivated');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (endDate !== undefined && endDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>endDate, 'endDate');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (startDate !== undefined && startDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startDate, 'startDate');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsHnodeActivityLogPojo>(`${this.configuration.basePath}/hnode-activity-logs/search/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
