/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationApiClientDto } from './verification-api-client-dto';


export interface CloneClientDto { 
    verificationApiClientDto?: VerificationApiClientDto;
    callsFromClone?: number;
    callsToClone?: number;
    customerStatus?: CloneClientDto.CustomerStatusEnum;
}
export namespace CloneClientDto {
    export type CustomerStatusEnum = 'ACTIVE' | 'INACTIVE' | 'PENDING';
    export const CustomerStatusEnum = {
        Active: 'ACTIVE' as CustomerStatusEnum,
        Inactive: 'INACTIVE' as CustomerStatusEnum,
        Pending: 'PENDING' as CustomerStatusEnum
    };
}


