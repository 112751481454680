import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent implements OnInit {

 user: any;
 stop$ = new Subject();


  constructor() {
  }

  ngOnInit(): void {

  }

  takeMeBack() {
    if (this.user) {
      window.location.href = '/dashboard';
    } else {
      // this.authenticationService.login({prompt: 'login'});
    }
  }

  ngOnDestroy(): void {
    // this.stop$.next();
    // this.stop$.complete();
  }
}
