/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { HCloneActionRequestPojo } from '../model/models';
import { NewHcloneActionRequestDto } from '../model/models';
import { QueryResultsHCloneActionRequestPojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface ApproveCloneActionRequestRequestParams {
    id: number;
}

export interface CreateCloneActionRequestRequestParams {
    data: NewHcloneActionRequestDto;
}

export interface DisapproveCloneActionRequestRequestParams {
    id: number;
    reason: string;
}

export interface GetCloneDetailsRequestParams {
    id: number;
}

export interface SearchActionRequestsRequestParams {
    approvalStatus?: 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    lastUpdatedBy?: string;
    completedAt?: string;
    dispatched?: boolean;
    requestReason?: string;
    acknowledged?: boolean;
    lastUpdatedAt?: string;
    approvedBy?: string;
    hClone?: string;
    completed?: boolean;
    type?: 'CLEAR_DATA' | 'DISCONNECT' | 'DEACTIVATE' | 'REACTIVATE' | 'RECONNECT';
    processingNote?: string;
    acknowledgedAt?: string;
    hcloneId?: number;
    deactivatedBy?: string;
    dispatchedAt?: string;
    createdAt?: string;
    createdBy?: string;
    requestId?: string;
    processedAt?: string;
    dateDeactivated?: string;
    id?: number;
    status?: 'ACTIVE' | 'INACTIVE' | 'PENDING';
    offset?: number;
    hcloneName?: string;
    limit?: number;
    createdBefore?: string;
    createdAfter?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class HCloneActionRequestControllerService {

    protected basePath = 'http://hgs.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approveCloneActionRequest(requestParameters: ApproveCloneActionRequestRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public approveCloneActionRequest(requestParameters: ApproveCloneActionRequestRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public approveCloneActionRequest(requestParameters: ApproveCloneActionRequestRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public approveCloneActionRequest(requestParameters: ApproveCloneActionRequestRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling approveCloneActionRequest.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/hgs-action-requests/${encodeURIComponent(String(id))}/approve`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCloneActionRequest(requestParameters: CreateCloneActionRequestRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HCloneActionRequestPojo>;
    public createCloneActionRequest(requestParameters: CreateCloneActionRequestRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<HCloneActionRequestPojo>>;
    public createCloneActionRequest(requestParameters: CreateCloneActionRequestRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<HCloneActionRequestPojo>>;
    public createCloneActionRequest(requestParameters: CreateCloneActionRequestRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const data = requestParameters.data;
        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling createCloneActionRequest.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (data !== undefined && data !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>data, 'data');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<HCloneActionRequestPojo>(`${this.configuration.basePath}/hgs-action-requests`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public disapproveCloneActionRequest(requestParameters: DisapproveCloneActionRequestRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public disapproveCloneActionRequest(requestParameters: DisapproveCloneActionRequestRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public disapproveCloneActionRequest(requestParameters: DisapproveCloneActionRequestRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public disapproveCloneActionRequest(requestParameters: DisapproveCloneActionRequestRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling disapproveCloneActionRequest.');
        }
        const reason = requestParameters.reason;
        if (reason === null || reason === undefined) {
            throw new Error('Required parameter reason was null or undefined when calling disapproveCloneActionRequest.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (reason !== undefined && reason !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reason, 'reason');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/hgs-action-requests/${encodeURIComponent(String(id))}/disapprove`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCloneDetails(requestParameters: GetCloneDetailsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HCloneActionRequestPojo>;
    public getCloneDetails(requestParameters: GetCloneDetailsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<HCloneActionRequestPojo>>;
    public getCloneDetails(requestParameters: GetCloneDetailsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<HCloneActionRequestPojo>>;
    public getCloneDetails(requestParameters: GetCloneDetailsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCloneDetails.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<HCloneActionRequestPojo>(`${this.configuration.basePath}/hgs-action-requests/${encodeURIComponent(String(id))}`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchActionRequests(requestParameters: SearchActionRequestsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsHCloneActionRequestPojo>;
    public searchActionRequests(requestParameters: SearchActionRequestsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsHCloneActionRequestPojo>>;
    public searchActionRequests(requestParameters: SearchActionRequestsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsHCloneActionRequestPojo>>;
    public searchActionRequests(requestParameters: SearchActionRequestsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const approvalStatus = requestParameters.approvalStatus;
        const lastUpdatedBy = requestParameters.lastUpdatedBy;
        const completedAt = requestParameters.completedAt;
        const dispatched = requestParameters.dispatched;
        const requestReason = requestParameters.requestReason;
        const acknowledged = requestParameters.acknowledged;
        const lastUpdatedAt = requestParameters.lastUpdatedAt;
        const approvedBy = requestParameters.approvedBy;
        const hClone = requestParameters.hClone;
        const completed = requestParameters.completed;
        const type = requestParameters.type;
        const processingNote = requestParameters.processingNote;
        const acknowledgedAt = requestParameters.acknowledgedAt;
        const hcloneId = requestParameters.hcloneId;
        const deactivatedBy = requestParameters.deactivatedBy;
        const dispatchedAt = requestParameters.dispatchedAt;
        const createdAt = requestParameters.createdAt;
        const createdBy = requestParameters.createdBy;
        const requestId = requestParameters.requestId;
        const processedAt = requestParameters.processedAt;
        const dateDeactivated = requestParameters.dateDeactivated;
        const id = requestParameters.id;
        const status = requestParameters.status;
        const offset = requestParameters.offset;
        const hcloneName = requestParameters.hcloneName;
        const limit = requestParameters.limit;
        const createdBefore = requestParameters.createdBefore;
        const createdAfter = requestParameters.createdAfter;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (approvalStatus !== undefined && approvalStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>approvalStatus, 'approvalStatus');
        }
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (completedAt !== undefined && completedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completedAt, 'completedAt');
        }
        if (dispatched !== undefined && dispatched !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dispatched, 'dispatched');
        }
        if (requestReason !== undefined && requestReason !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requestReason, 'requestReason');
        }
        if (acknowledged !== undefined && acknowledged !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>acknowledged, 'acknowledged');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (approvedBy !== undefined && approvedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>approvedBy, 'approvedBy');
        }
        if (hClone !== undefined && hClone !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hClone, 'HClone');
        }
        if (completed !== undefined && completed !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>completed, 'completed');
        }
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'type');
        }
        if (processingNote !== undefined && processingNote !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>processingNote, 'processingNote');
        }
        if (acknowledgedAt !== undefined && acknowledgedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>acknowledgedAt, 'acknowledgedAt');
        }
        if (hcloneId !== undefined && hcloneId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hcloneId, 'hcloneId');
        }
        if (deactivatedBy !== undefined && deactivatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deactivatedBy, 'deactivatedBy');
        }
        if (dispatchedAt !== undefined && dispatchedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dispatchedAt, 'dispatchedAt');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (requestId !== undefined && requestId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requestId, 'requestId');
        }
        if (processedAt !== undefined && processedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>processedAt, 'processedAt');
        }
        if (dateDeactivated !== undefined && dateDeactivated !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDeactivated, 'dateDeactivated');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (hcloneName !== undefined && hcloneName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hcloneName, 'hcloneName');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsHCloneActionRequestPojo>(`${this.configuration.basePath}/hgs-action-requests`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
