import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-pending-request-error-modal',
  templateUrl: './pending-request-error-modal.component.html',
  styleUrls: ['./pending-request-error-modal.component.css']
})
export class PendingRequestErrorModalComponent implements OnInit {
  @Input() modalHeader;
  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  close(): void {
    this.bsModalRef.hide();
  }

}
