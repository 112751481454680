/**
 * Newsclip API
 * The Newsclip RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface HclonePersonDataPojo { 
    nin?: string;
    createdAt?: string;
    destinationClone?: string;
    transactionReference?: string;
    dateAcknowledged?: string;
    cloneAcknowledgementId?: string;
    payloadSize?: string;
}

